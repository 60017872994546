function xorStrings(a, b) {
    let s = '';
    // use the longer of the two words to calculate the length of the result
    for (let i = 0; i < Math.max(a.length, b.length); i++) {
        // append the result of the char from the code-point that results from
        // XORing the char codes (or 0 if one string is too short)
        s += String.fromCharCode(
            (a.charCodeAt(i) || 0) ^ (b.charCodeAt(i) || 0)
        );
    }
    return s;
}

var email = xorStrings(decodeURIComponent('%03%14%25%04%04%00%18%03%01%03%02%08%0C%1B%1E%0B%01%13O%10%07J%12%0D'), 'nqenaskjdhgaksjgdjashdgf');
var phone = xorStrings('_F_WBVQPUNT', 'oqjeubahdva');

injectDetails('contact-email', `<a href="mailto:${email}">${email}</a>`);
// injectDetails('contact-phone', '&nbsp;');

function injectDetails(className, content) {
    var elements = document.getElementsByClassName(className);
    for(var elementIdx in elements)
    {
        if(elements.hasOwnProperty(elementIdx))
        {
            var element = elements[elementIdx];
            element.innerHTML = content;
        }
    }
}
